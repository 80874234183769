<template>
	<div class="s-manage-teacher-attendance-record-wrapper">
		<!-- 考勤管理 > 缺卡设置 -->
		<div v-if="!errorShow">
			<loading :loadingShow="loadingShow"></loading>
			<div v-if="!loadingShow">
				<component
					:is="components[idx]" :checkingsInId="checkingsInId"
					@checkingsInIdChange="checkingsInIdChange" @expandManagement="expandManagement"
					@expandFilterUpdateManagement="expandFilterUpdateManagement"
					@setPageInitTabs="setPageInitTabs"
				/>
			</div>
		</div>
		<error v-else :errorShow="errorShow"></error>
	</div>
</template>

<script>
	import {
		Error,
		Loading,
		TableData,
		Pagination,
		DialogWrapper,
		debounce
	} from "common-local"
	import OperationBtn from './MissingCardSetting/OperationBtn.vue';

	export default {
		name: "missingCardSetting",
		components: {
			Error,
			Loading,
			TableData,
			Pagination,
			DialogWrapper,
			ExpandFilter:() => import(/* webpackChunkName:'ExpandFilter' */"./MissingCardSetting/ExpandFilter.vue"),
			ExpandFilterUpdate:() => import(/* webpackChunkName:'ExpandFilterUpdate' */"./MissingCardSetting/VirtulaList/ExpandFilterUpdate.vue"),
			OperationBtn
		},
		data() {
			return {
				components: ['ExpandFilter', 'ExpandFilterUpdate'],
				idx: 0,
				checkingsInId: undefined,
				// 功能组件
				errorShow: false,
				loadingShow: false,
				loadingTable: false,
			}
		},
		computed: {

		},
		created() {

		},
		mounted() {

		},
		methods: {
			checkingsInIdChange(id) {
				this.checkingsInId = id
			},
			expandManagement() {
				console.log("缺卡设置列表页")
				this.idx = 0;
			},
			expandFilterUpdateManagement() {
				console.log("缺卡设置更新页")
				this.idx = 1;
			},
			setPageInitTabs() {
				this.$eventDispatch('setGlobalPageType', 'list')
				this.idx = 0;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.s-manage-teacher-attendance-record-wrapper {
		// margin-right: 10px;
		box-sizing: border-box;
		border-radius: 6px;
	}
</style>
