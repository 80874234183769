<template>
    <div class="operation-btn">
        <div class="import">
            <el-button v-has-permi="['schoolcheckattendancerecord:import']" @click="importFile" type="primary">导入</el-button>
            <el-button v-has-permi="['schoolcheckattendancerecord:export']" @click="exportFile" type="success">导出</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "OperationBtn",
    methods: {
        importFile () {
            this.$emit('importFile')
        },
        exportFile () {
            this.$emit('exportFile')
        }
    }

}
</script>
<style lang="scss" scoped>
    .operation-btn {
        margin-top: 10px;
        height: 66px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        background-color: #FFFFFF;
        padding: 15px;
        border-radius: 6px;
        .import {
            display: flex;
        }
    }
</style>